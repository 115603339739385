<template>
    <div>
        <CRow>
            <CCol md="12" lg="6" style="border-right: 1px solid #d8dbe0;">
                <div style="width: 100%;">
                    <barVerticalNew 
                        :series="seriesLineSize"
                        :labels="categoriesBar"
                        :titleYaxis="$t('label.QuantityContainers')"
                        :bandera="true"
                        height="400px"
                    >
                    </barVerticalNew>
                </div>
            </CCol>
            <CCol md="12" lg="6">
                <div style="width: 100%;">
                    <barHorizontalNew
                        colorDatalabels="#FFF"
                        :series="seriesLinePortActivity"
                        :labels="categoriesBar"
                        :titleXaxis="$t('label.QuantityContainers')"
                        :bandera="true"
                        height="400px"
                    >
                    </barHorizontalNew>
                </div>
            </CCol>
        </CRow>
        <table class="table table-bordered text-center">
            <thead>
                <tr class="text-white">
                    <th style="background: #ededed;"></th>
                    <th style="background: #00343f;">20 FT</th>
                    <th style="background: #ffbf67;">40 FT</th>
                    <th style="background: #e9b7a6;">45 FT</th>
                    <th style="background: #52a3a6;">{{ this.$t('label.Export') }}</th>
                    <th style="background: #606060;">{{ this.$t('label.Import') }}</th>
                    <th style="background: #5e548e;">{{ this.$t('label.ship_land_ship') }}</th>
                    <th style="background: #007a7b;">{{ this.$t('label.ship_ship') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in ShippingLine" :key="item.IdX">
                    <td style="background: #ededed;">{{ item.ShippingLineCode }}</td>
                    <td>{{ item.Cant20Ft }}</td>
                    <td>{{ item.Cant40Ft }}</td>
                    <td>{{ item.Cant45Ft }}</td>
                    <td>{{ item.CantExport }}</td>
                    <td>{{ item.CantImport }}</td>
                    <td>{{ item.CantShipLandShip }}</td>
                    <td>{{ item.CantShipShip }}</td>
                </tr>
                <tr>
                    <td style="background: #ededed;"><strong>TOTAL</strong></td>
                    <td><strong>{{ TotalShippingLine.Cant20Ft }}</strong></td>
                    <td><strong>{{ TotalShippingLine.Cant40Ft }}</strong></td>
                    <td><strong>{{ TotalShippingLine.Cant45Ft }}</strong></td>
                    <td><strong>{{ TotalShippingLine.CantExport }}</strong></td>
                    <td><strong>{{ TotalShippingLine.CantImport }}</strong></td>
                    <td><strong>{{ TotalShippingLine.CantShipLandShip }}</strong></td>
                    <td><strong>{{ TotalShippingLine.CantShipShip }}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import barHorizontalNew from "@/pages/situacion-operativa-actual/components/barHorizontalNew";
import barVerticalNew from "@/pages/situacion-operativa-actual/components/barVerticalNew";

function data() {
    return {
        ShippingLine: [],
    }
}
//methods


//computed
function seriesLineSize(){
    let data = [
        {
            label : '20',
            data : [],
            backgroundColor: '#00343f',
        },
        {
            label : '40',
            data : [],
            backgroundColor: '#ffbf67',
        },
        {
            label : '45',
            data : [],
            backgroundColor: '#e9b7a6',
        },
    ];
    this.ShippingLine.map(item => {
        data[0].data.push(item.Cant20Ft);
        data[1].data.push(item.Cant40Ft);
        data[2].data.push(item.Cant45Ft);
    })

    return data;
}

function categoriesBar(){
    let data = [];
    this.ShippingLine.map(item => {
        data.push(item.ShippingLineCode);
    })
    return data;
}

function seriesLinePortActivity(){
    let data = [
        {
            label : this.$t('label.Export'),
            data : [],
            backgroundColor: '#52a3a6',
        },
        {
            label : this.$t('label.Import'),
            data : [],
            backgroundColor: '#606060',
        },
        {
            label : this.$t('label.ship_land_ship'),
            data : [],
            backgroundColor: '#5e548e',
        },
        {
            label : this.$t('label.ship_ship'),
            data : [],
            backgroundColor: '#007a7b',
        },
    ];
    this.ShippingLine.map(item => {
        data[0].data.push(item.CantExport);
        data[1].data.push(item.CantImport);
        data[2].data.push(item.CantShipLandShip);
        data[3].data.push(item.CantShipShip);
    })

    return data;
}

function TotalShippingLine() {
    let Total = {
        Cant20Ft: 0,
        Cant40Ft: 0,
        Cant45Ft: 0,
        CantExport: 0,
        CantImport: 0,
        CantShipLandShip: 0,
        CantShipShip: 0,
    };

    this.ShippingLine.map(item => {
        Total.Cant20Ft+=item.Cant20Ft;
        Total.Cant40Ft+=item.Cant40Ft;
        Total.Cant45Ft+=item.Cant45Ft;
        Total.CantExport+=item.CantExport;
        Total.CantImport+=item.CantImport;
        Total.CantShipLandShip+=item.CantShipLandShip;
        Total.CantShipShip+=item.CantShipShip;
    })

    return Total;
}

export default {
    name:"Shipping-line",
    data,
    mixins: [],
    props: {
        ShippingLineJson: {
            type: Array,
            default: function () {
                return [] 
            },
        }
    },
    methods:{

    },
    components:{
        barHorizontalNew,
        barVerticalNew,
    },
    computed:{
        TotalShippingLine,
        seriesLinePortActivity,
        categoriesBar,
        seriesLineSize,
        ...mapState({
            VisitId: state => state.Management.VisitId,
            CarruselTab: state => state.Management.CarruselTab,
        })
    },
    watch:{
        CarruselTab: function (val, oldVal) {
            if (val==5) {
                this.ShippingLine = this.ShippingLineJson;
            };
            if (oldVal==5) {
                this.ShippingLine = [];
            }
        }
    }
}
</script>
<style scoped>
.lapse-table{
width: 100%;
border-collapse: collapse;
}
table.lapse-table tr:last-child td {
    border-bottom: none;
}
table.lapse-table tr:first-child th {
    border-top: none !important;
}
table.lapse-table th{
border-left: none !important;
border-bottom: none;
}
table.lapse-table th:last-child {
border-right: none !important;
}
table.lapse-table td:first-child {
border-left: none !important;
border-right: none !important;
}
table.lapse-table td:last-child {
border-right: none !important;
}
</style>