<template>
    <CCol sm="12">
        <loading-overlay
            :active="Loading"
            :is-full-page="true"
            loader="bars"
        />
        <CCard>
            <CCardHeader class="text-center bg-dark text-white p-1">
                <CCol sm="12">
                    <CRow>
                        <div class="col-sm-12 col-lg-5">
                            <CInput
                                :label="$t('label.statusVisit')"
                                :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                                :value="VIsitStatus"
                                class="mb-0"
                                disabled
                                addLabelClasses="text-right"
                            />
                        </div>
                        <div class="col-sm-12 col-lg-7">
                            <CRow>
                                <div class="col-sm-12 col-lg-11">
                                    <CInput
                                        :label="$t('label.itinerary')"
                                        :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'}"
                                        :value="Itinerary"
                                        class="mb-0"
                                        disabled
                                        addLabelClasses="text-right"
                                    />
                                </div>
                                <div 
                                    @click="closeCollapse()"
                                    class="col-sm-12 col-lg-auto d-flex align-items-center justify-content-end"
                                 >
                                    <CIcon name="x"/>
                                </div>
                            </CRow>
                        </div>
                    </CRow>
                </CCol>
            </CCardHeader>
            <CCardBody class="pt-1">
                <div class="col-sm-12 d-flex justify-content-between">
                    <CButton 
                        color="edit"
                        class="mr-1"
                        size="sm" 
                        @click=" onIntervalCarousel"
                    >
                        <img
                            :src="pauseOrPlay"
                            class="c-icon"
                            style="filter: invert(1);"
                        />
                    </CButton>
                    <CButton 
                        color="danger"
                        size="sm" 
                        @click="onBtnPdf()"
                    >
                        <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                    </CButton>
                </div>
                
                <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
                    <a
                        href="#" 
                        @click.prevent="disminuirCarrusel"
                        class="arrow left"
                    ></a>
                </div>
                <div class="flex-grow-1" style="height:100%">
                    <CCollapse :show="CarruselTab==1" class="p-arrows-carousel">
                        <TimeLine
                            :TimeLineJson="TimeLineJson"
                        />
                    </CCollapse>
                    <CCollapse :show="CarruselTab==2" class="p-arrows-carousel">
                        <TimeInPort
                            :VisitSummaryJson="VisitSummaryJson"
                            @FgDate="FgDate=$event"
                        />
                    </CCollapse>
                    <CCollapse :show="CarruselTab==3" class="p-arrows-carousel">
                        <Pruductivity
                            :ProdByDayLapseJson="ProdByDayLapseJson"
                        />
                    </CCollapse>
                    <CCollapse v-if="bulkCarrier==1" :show="CarruselTab==4" class="p-arrows-carousel">
                        <SizeType
                            :SizeJson="SizeJson"
                            :TypeJson= "TypeJson"
                        />
                    </CCollapse>
                    <CCollapse v-if="bulkCarrier==1" :show="CarruselTab==5" class="p-arrows-carousel">
                        <ShippingLine
                            :ShippingLineJson="ShippingLineJson"
                        />
                    </CCollapse>
                    <CCollapse v-if="bulkCarrier==1" :show="CarruselTab==6" class="p-arrows-carousel">
                        <PlannedVsExecute
                            :MovsPlannedVsExecutedJson="MovsPlannedVsExecutedJson"
                        />
                    </CCollapse>
                    <CCollapse :show="bulkCarrier==1?CarruselTab==7:CarruselTab==4" class="p-arrows-carousel">
                        <MovementsByCrane
                            :VisitCranesOpsJson="VisitCranesOpsJson"
                        />
                    </CCollapse>
                    <CCollapse :show="bulkCarrier==1?CarruselTab==8:CarruselTab==5" class="p-arrows-carousel">
                        <InactivityTimes
                            :VisitCranesOpsJson="VisitCranesOpsJson"
                        />
                    </CCollapse>
                    <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
                        <a
                            href="#" 
                            @click.prevent="aumentarCarrusel"
                            class="arrow right"
                        ></a>
                    </div>
                </div>
            </CCardBody>
        </CCard>
    </CCol>
</template>
<script>
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import TimeInPort from './time-in-port';
    import TimeLine from './timeline';
    import Pruductivity from './Pruductivity'
    import SizeType from './size-and-type'
    import ShippingLine from './Shipping-line'
    import PlannedVsExecute from './PlannedVsExecute'
    import MovementsByCrane from './Movements-By-Crane'
    import InactivityTimes from './Inactivity-Times'
    function data() {
        return {
            Loading: false,
            FgInterval: false,
            FgDate: true,
            IntervalId: null,
            intervalCarouselId: null,
            TimeLineJson: [],
            VisitSummaryJson: {},
            ProdByDayLapseJson: {},
            SizeJson: [],
            TypeJson: [],
            ShippingLineJson: [],
            MovsPlannedVsExecutedJson: [],
            VisitCranesOpsJson: [],
        }
    }
    //methods
    function FinalReportV2() {
        this.Loading = true;
        clearInterval(this.intervalId);
        let Service = this.bulkCarrier==1?'VisitContainerShipFinalReportV2':'VisitMetalScrapFinalReportvV2';
        this.$http.get(Service, {VisitId: this.VisitId})
            .then((response) => {
                let list = response.data.data;
                this.TimeLineJson = list[0]?.TimeLineJson ? list[0].TimeLineJson : [];
                this.VisitSummaryJson = list[0]?.VisitSummaryJson[0] ? list[0].VisitSummaryJson[0] : {};
                this.ProdByDayLapseJson = list[0]?.ProdByDayLapseJson[0] ? list[0].ProdByDayLapseJson[0] : {};
                this.SizeJson = list[0]?.SizeJson ? list[0].SizeJson : [];
                this.TypeJson = list[0]?.TypeJson ? list[0].TypeJson : [];
                this.ShippingLineJson = list[0]?.ShippingLineJson ? list[0].ShippingLineJson : [];
                this.MovsPlannedVsExecutedJson = list[0]?.MovsPlannedVsExecutedJson ? list[0].MovsPlannedVsExecutedJson : [];
                this.VisitCranesOpsJson = list[0]?.VisitCranesOpsJson ? list[0].VisitCranesOpsJson : [];
                this.$store.state.Management.CarruselTab = 1;
                this.Loading = false;
            }).catch(err => {
                this.TimeLineJson = [];
                this.VisitSummaryJson = {};
                this.ProdByDayLapseJson = {};
                this.SizeJson = [];
                this.TypeJson = [];
                this.ShippingLineJson = [];
                this.MovsPlannedVsExecutedJson = [];
                this.VisitCranesOpsJson = [];
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).finally(() => {
                this.$store.state.Management.CarruselTab = 1;
                this.interval();
                this.Loading = false;
            });
    }

    function interval() {
        this.intervalId = setInterval(() => {
            if (!this.Loading) {
                this.FinalReportV2();
            }
        }, 300000)
    }

    function intervalCarousel() {
        this.intervalCarouselId = setInterval(() => {
            if(this.$store.state.Management.CarruselTab < (this.bulkCarrier==1?8:5)){
                this.$store.state.Management.CarruselTab = this.$store.state.Management.CarruselTab + 1;
            }else{
                this.$store.state.Management.CarruselTab = 0;
            }
        }, 300000/((this.bulkCarrier==1?8:5)+1))
    }

    function onIntervalCarousel() {
        this.FgInterval = !this.FgInterval;
        if (this.FgInterval) {
            this.intervalCarousel();
        }else {
            clearInterval(this.intervalCarouselId);
        }
    }

    function onBtnPdf() {
        let TotalPage = this.bulkCarrier==1?8:5;
        if (this.ProdByDayLapseJson.DetailJson.length>15) {
            TotalPage+=Math.ceil(this.ProdByDayLapseJson.DetailJson.length/15)==0?0:Math.ceil(this.ProdByDayLapseJson.DetailJson.length/15)-1;
        };
        let pageInfo = {
            VisitId: this.VisitId,
            itinerarySelected: this.itinerarySelected,
            BulkCarrier: this.bulkCarrier,
            TotalPage: TotalPage,
            FgDate: this.FgDate,
        };
        localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

        let routeData = this.$router.resolve({name: 'PdfManagement'});
        window.open(routeData.href, '_blank');
    }

    function disminuirCarrusel(){
        if(this.$store.state.Management.CarruselTab !== 1){
            this.$store.state.Management.CarruselTab = this.$store.state.Management.CarruselTab - 1;
        }
    }

    function aumentarCarrusel(){
        if(this.$store.state.Management.CarruselTab < (this.bulkCarrier==1?8:5)){
            this.$store.state.Management.CarruselTab = this.$store.state.Management.CarruselTab + 1;
        }
    }

    function closeCollapse(){
        this.$store.state.Management.CarruselTab=0;
        this.$store.state.Management.collapseManagement=false;
    }
    //computed
    function minCarrusel(){
        return this.$store.state.Management.CarruselTab > 1
    }

    function maxCarrusel(){
        return this.$store.state.Management.CarruselTab < (this.bulkCarrier==1?8:5)
    }

    function VIsitStatus(){
        if(this.collapseManagement){
            let _lang = this.$i18n.locale;
            return _lang == 'es'? this.itinerarySelected.VisitStatusEs : this.itinerarySelected.VisitStatusEn;
        }else{  
            return '';
        }
    }

    function Itinerary(){
        if(this.collapseManagement){
            return this.itinerarySelected.VesselName+', '+this.itinerarySelected.Voyage+', '+
                this.itinerarySelected.LabelArrival+' '+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+
                ' - '+this.itinerarySelected.LabelDeparture+' '+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure);
        }else{
            return "";
        }
    }

    function pauseOrPlay() {
        return this.FgInterval ? '/img/iconos/pause-svgrepo-com.svg' : '/img/iconos/play-svgrepo-com.svg';
    }

    //watch
    function collapseManagement(newQuestion) {
        if (newQuestion==true) {
            this.FinalReportV2();
        }else{
            this.FgDate = true;
            this.TimeLineJson = [];
            this.VisitSummaryJson = {};
            this.ProdByDayLapseJson = {};
            this.SizeJson = [];
            this.TypeJson = [];
            this.ShippingLineJson = [];
            this.MovsPlannedVsExecutedJson = [];
            this.VisitCranesOpsJson = [];
            this.FgInterval = false;
            clearInterval(this.intervalId);
            clearInterval(this.intervalCarouselId);
        }
    }

    export default {
        name:"Management-index",
        data,
        beforeDestroy(){
            clearInterval(this.intervalId);
            clearInterval(this.intervalCarouselId);
        },
        mixins: [],
        methods:{
            FinalReportV2,
            interval,
            intervalCarousel,
            onIntervalCarousel,
            onBtnPdf,
            disminuirCarrusel,
            aumentarCarrusel,
            closeCollapse,
        },
        components:{
            TimeInPort,
            TimeLine,
            Pruductivity,
            SizeType,
            ShippingLine,
            PlannedVsExecute,
            MovementsByCrane,
            InactivityTimes,
        },
        computed:{
            minCarrusel,
            maxCarrusel,
            VIsitStatus,
            Itinerary,
            pauseOrPlay,
            ...mapState({
                collapseManagement : state=> state.Management.collapseManagement,
                VisitId: state => state.Management.VisitId,
                itinerarySelected: state => state.Management.itinerarySelected,
                CarruselTab: state => state.Management.CarruselTab,
                bulkCarrier: state => state.Management.bulkCarrier,
            })
        },
        watch:{
            collapseManagement,
        }
    }
</script>
<style lang="scss" >

.p-arrows-carousel {
  padding: 0.5rem;
}
.arrows-carousel-left {
  position: absolute;
  top: 0;
  left: -2%;
  height: 100%;
}
.arrows-carousel-right {
  position: absolute;
  top: 0;
  right: -2%;
  height: 100%;
}

.container-arrow-carousel {
  width: 32px;
  height: 100%;
  // background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
}
.container-arrow-carousel > .arrow {
	width: 30px;
	height: 30px;
	background: transparent;
	border-top: 5px solid grey;
	border-right: 5px solid grey;
	box-shadow: 0 0 0 lightgray;
	transition: all 200ms ease;
	
	&.left {
		left: 0;
		transform: translate3d(35%,0,0) rotate(-135deg);
	}

	&.right {
		right: 0;
		transform: translate3d(-35%,0,0) rotate(45deg);
	}
	
	&:hover {
		border-color: orange;
		box-shadow: 3px -3px 0 grey;
	}
	
	&:before { // for better hit area
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-40%,-60%) rotate(45deg);
		width: 200%;
		height: 200%;
	}
}
</style>
