<template>
    <div>
        <CCol sm="12" style="height:350px">
            <MultipleBar
                height="350px"
                :labels="getCategories"
                :series="getSeries"
                positionLegend="bottom"
                :getScales="getScales"
            />
        </CCol>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import MultipleBar from "@/pages/situacion-operativa-actual/components/multipleBarNew";

function data() {
    return {
        CranesOps: [],
    }
}

//computed
function getCategories() {
    let data = [];
    this.CranesOps.map(item => {
        if (item.CraneAlias!='TOTAL') {
            data.push(item.CraneAlias);
        }
    })
    return data;
}

function getSeries(){
    let data = [{
      label: this.$t("label.DischargeMovements"),
      data: [],
      backgroundColor: '#52a3a6',
      stack: 'Stack 0',
      yAxisID: 'y',
    },
    {
      label: this.$t("label.LoadMovements"),
      data: [],
      backgroundColor: '#2196f3',
      stack: 'Stack 0',
      yAxisID: 'y',
    },
    {
      label: this.$t("label.HatchMovements"),
      data: [],
      backgroundColor: '#26E7A6',
      stack: 'Stack 0',
      yAxisID: 'y',
    },
    {
      label: this.$t("label.averageMovementsPerHrs"),
      data: [],
      backgroundColor: '#606060',
      stack: 'Stack 1',
      yAxisID: 'y1',
    }];
    this.CranesOps.map(item => {
        if (item.CraneAlias!='TOTAL') {
            if (this.bulkCarrier==1) {
              data[0].data.push(item.Discharge?item.Discharge:0);
              data[1].data.push(item.CantLoad?item.CantLoad:0);
            } else {
              data[0].data.push(item.CantDischarge?item.CantDischarge:0);
              data[1].data.push(item.LoadOnHold?item.LoadOnHold:0);
            }
            data[2].data.push(item.HatchCover?item.HatchCover:0);
            data[3].data.push(item.GrossMovsXHour?item.GrossMovsXHour:0);
        }
    })
    return data;
}

function getScales(){
  return {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      display: true,
      position: 'left',
      title: {
        display: true,
        text: this.$t("label.MovsNumbers") // array so it becomes multi lined
      },
    },
    y1: {
      display: true,
      stacked: true,
      position: 'right',
      ticks:{
        color: "#e96418",
      },
      grid: {
        color: "#e96418",
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
      title: {
        display: true,
        color: '#e96418',
        text: this.$t("label.averageMovementsPerHrs") // array so it becomes multi lined
      },
    },
  }
}

export default {
    name:"MovementsByCrane",
    data,
    mixins: [],
    props:{
        VisitCranesOpsJson:{
            type: Array,
            default: function () {
                return [] 
            },
        }
    },
    methods:{},
    components:{
        MultipleBar,
    },
    computed:{
        getScales,
        getSeries,
        getCategories,
        ...mapState({
            CarruselTab: state => state.Management.CarruselTab,
            bulkCarrier: state => state.Management.bulkCarrier,
        })
    },
    watch:{
        CarruselTab: function (val, oldVal) {
            if (val==(this.bulkCarrier==1?7:4)) {
                this.CranesOps = this.VisitCranesOpsJson;
            }
            if (oldVal==(this.bulkCarrier==1?7:4)) {
                this.CranesOps = [];
            }
        }
    }
}
</script>
<style scoped>
.circle{
    height: 15px;
    width: 15px;
    background: #666666;
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
</style>